class CurrencySymbol {
  getSymbol() : string {
    const ITEM_NAME = 'currencySymbol';

    let currencySymbol = localStorage.getItem(ITEM_NAME);
    if (currencySymbol == null) {
      /*await fetch('https://www.geoplugin.net/json.gp')
        .then((response) => {
          let json: any = response.json();
          localStorage.setItem(
            'currencySymbol',
            json.geoplugin_currencySymbol_UTF8
          );
        })
        .catch((err) => console.log('Get geo infos error:', err));*/
      if (navigator.language == 'en-GB') {
        currencySymbol = '£';
      } else currencySymbol = '€';

      localStorage.setItem(ITEM_NAME, currencySymbol);
    }

    return currencySymbol;
  }
}

export default CurrencySymbol;
